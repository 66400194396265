import React from 'react';
import pl from '../../../../assets/pl.webp';

const MenuFlags = () => {
  return (
    <div className="menu-flags-wrapper">
      <div className="menu-flags">
        <div className="menu-flag"></div>
        <div className="menu-flag">
          <img src={pl} alt="pl"></img>
        </div>
      </div>
    </div>
  );
};

export default MenuFlags;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import Home from './components/Home/Home.jsx';
import About from './components/About/About.jsx';
import Contact from './components/Contact/Contact.jsx';
import Offert from './components/Ofert/Offert.jsx';
import Order from './components/Order/Order.jsx';
import './global-styles/global_styles.sass';
// import Emitter from './components/services/emitter.jsx';
import Employees from './components/employees/employees.jsx';
import Login from './components/Admin/Login/Login.jsx';
import HomeCMS from './components/Admin/Cms/Home';
import Blog from './components/Blog/Blog.jsx';
import Article from './components/Blog/Article/Article.jsx';
import Home from './components/Home/Home';
import Privacy from './components/Privacy';
import Poz from './components/Poz/index';
// import { createBrowserHistory } from 'history';
import Diagnostic from './components/Diagnostic/index';
const ProctectedRoute = ({ children }) => {
  // const navigate = useNavigate()
  // const auth = getAuth()
  // console.log(auth)
  // let _user;
  // await onAuthStateChanged(auth, (user) => {
  //   console.log(user)
  //   if(user) _user = user
  // })
  //   if(_user?.uid){
  //     return children
  //   }else{
  //     console.log('nie zalogowany')
  //     // window.location.href = '/'
  //     return null
  //   }
  return children;
};

const App = () => {
  // useEffect(() => {
  //   Emitter.on('SHOW_YOGA', () => {
  //     window.przybyl = {
  //       ref: 'yoga',
  //     };
  //   });
  // }, []);

  // const browserHistory = createBrowserHistory();

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/offert" element={<Offert />} />
        <Route exact path="/order" element={<Order />} />
        <Route exact path="/pracownicy" element={<Employees />} />
        <Route exact path="/admin-login" element={<Login />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route path="/blog/:title" element={<Article />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/poz" element={<Poz />} />
        <Route path="diagnostyka" element={<Diagnostic />} />
        <Route
          exact
          path={'/admin'}
          element={
            <ProctectedRoute>
              <HomeCMS />
            </ProctectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
import React, { createRef } from 'react';

import './Mobile.sass';
import mainIMG from '../../../assets/mobile-main.webp';
import yogaIMG from '../../../assets/yoga.webp';
import teamIMG from '../../../assets/team.webp';
import contactIMG from '../../../assets/kontakt.webp';
import kobidoIMG from '../../../assets/kobido.webp';
import { Link } from 'react-router-dom';
export default class Mobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
    };
    this.charlie = createRef(null);
    this.setRedirect = this.setRedirect.bind(this);
    this.setRef = this.setRef.bind(this);
  }
  componentDidMount() {
    if (window.przybyl?.ref) {
      if (this.charlie) {
        this.charlie.current.scrollIntoView();
        window.przybyl.ref = null;
      }
    }
  }
  componentDidUpdate() {
    if (window.przybyl?.ref) {
      this.charlie.current.scrollIntoView();
      window.przybyl.ref = null;
    }
  }

  setRef(element) {
    this.charlie = element;
    const id =
      window.location.href.slice(window.location.href.indexOf('#') + 1) | '';
    if (id !== '') {
      // this.charlie.current.scrollIntoView({ smooth: true });
      // this.charlie.scrollIntoView({ smooth: true });
    }
  }
  setRedirect(url) {
    console.log(url);
    window.location.href = url;
  }
  render() {
    return (
      <>
        <div className="main-container">
          <Alfred />
          <Benjamin redi={this.setRedirect} />
          {/*<Charlie refa={this.charlie} />*/}
          {/*<CharlieSister />*/}
          <Xander redi={this.setRedirect} />
          <Dwayne />
          <Elon />
        </div>
      </>
    );
  }
}

function Alfred() {
  return (
    <>
      <div className="alfred-row" style={mainIMGStyle}>
        <div className="darken"></div>
        <h1>Świąteczne Rabaty!</h1>
        <p>Wybierz doskonały prezent dla bliskiej osoby!</p>
        <ul
          style={{
            listStyleType: 'none',
            padding: '0',
            color: '#fff',
            zIndex: '1',
          }}
        >
          <li>3x zabieg Kobido w cenie 450 zł</li>
          <li>3x masaż kręgosłupa w cenie 250 zł</li>
          <li>3x masaż leczniczy w cenie 350 zł</li>
        </ul>
        <p
          style={{
            color: '#fff',
            zIndex: '1',
            fontSize: '0.8em',
          }}
        >
          * Zakupu voucherów można dokonać w recepcji CRO-MED
        </p>
        <div className="btn-blue">
          <Link to="https://booksy.com/pl-pl/108831_cromed-centrum-fizjoterapii_fizjoterapia_16974_swarzedz#ba_s=sh_1">
            {' '}
            Sprawdź całą ofertę
          </Link>
        </div>
      </div>
    </>
  );
}

function Benjamin(props) {
  return (
    <>
      <div className="benjamin-row-blue">
        <h2>Zostań pacjentem</h2>
        <p>Sprawdź warunki deklaracji i zapisz się już teraz!</p>
        <div
          onClick={() =>
            // eslint-disable-next-line react/prop-types
            // props.redi(
            //   'https://booksy.com/pl-pl/108831_cromed-centrum-fizjoterapii_fizjoterapia_16974_swarzedz#ba_s=sh_1'
            // )
            (window.location.href = '/poz')
          }
          className="btn-blue"
        >
          <p>Zapisuję się!</p>
        </div>
      </div>
      <div className="benjamin-row">
        <h2>Umów się na wizyte!</h2>
        <p>Sprawdź ofertę i umów się już teraz!</p>
        <div
          onClick={() =>
            // eslint-disable-next-line react/prop-types
            props.redi(
              'https://booksy.com/pl-pl/108831_cromed-centrum-fizjoterapii_fizjoterapia_16974_swarzedz#ba_s=sh_1'
            )
          }
          className="btn-blue"
        >
          <p>Rezerwuje!</p>
        </div>
      </div>
    </>
  );
}
// eslint-disable-next-line no-unused-vars
function Charlie(props) {
  return (
    <>
      <div
        className="charlie-row"
        // eslint-disable-next-line react/prop-types
        ref={props.refa}
        id="charlie-row"
        style={yogaIMGStyle}
      >
        <div className="darken">
          <div className="new">
            <p>NOWOŚĆ!</p>
          </div>
        </div>
        <h1>JOGA</h1>
        <p>
          Sprawdź najnowszą oferte jogi!
          <br /> Na pewno znajdziesz coś dla siebie
        </p>
      </div>
    </>
  );
}
function Dwayne() {
  return (
    <>
      <div className="dwayne-row" style={teamIMGStyle}>
        <div className="darken">
          <div className="new">
            <p>ZESPÓŁ CROMED</p>
          </div>
        </div>
        <h1>NAJLEPSI SPECJALIŚCI</h1>
        <p>Sprawdź naszych profesjonalistów</p>
      </div>

      <div className="dwayne-sister-row">
        <h2>Poznaj nasz zespół</h2>
        <p>Kliknij poniżej w przycisk, aby poznać zespół cromed!</p>
        <Link push to="/pracownicy">
          <div className="btn-blue">Nasza Kadra</div>
        </Link>
      </div>
    </>
  );
}
// eslint-disable-next-line no-unused-vars
function CharlieSister() {
  return (
    <>
      <div className="charlie-sister-row">
        <h2>Vinyasa Yoga</h2>
        <p>
          to yoga, gdzie każdy ruch łączy się z oddechem <br /> i tworzy spójne
          flow. Jest to bardziej aktywna praktyka, gdzie główną część
          pozostajemy <br /> w płynnym ruchu.
        </p>
        <h2>Hatha Yoga</h2>
        <p>
          to tradycyjna yoga, gdzie dłuższą chwilę pozostajemy się w jednej
          pozycji. Jest to wolniejsza praktyka, gdzie poprzez ciało i oddech
          pracujemy <br /> z umysłem.
        </p>
        <h2>Spokojna Yoga</h2>
        <p>
          to zajęcia dedykowane dla osób, które nigdy wcześniej nie próbowały
          yogi lub osób początkujących. Na tych zajęciach poprzez praktykę
          dowiesz się, jak poprawnie wykonywać pozycje w yodze oraz poznasz
          podstawy filozofii yogi.
        </p>
        <table>
          <tr>
            <th></th>
            <th>Wt.</th>
            <th>Śr.</th>
            <th>Czw.</th>
            <th>Sob.</th>
          </tr>
          <tr>
            <td>9:00</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Joga</td>
          </tr>
          <tr>
            <td>10:30</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Mindfulness</td>
          </tr>
          <tr>
            <td>17:00</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>18:00</td>
            <td></td>
            <td>
              Zdrowy <br /> kręgosłup
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>18:30</td>
            <td>Joga</td>
            <td></td>
            <td>Joga</td>
            <td></td>
          </tr>
        </table>
      </div>
    </>
  );
}
function Xander(props) {
  return (
    <>
      <div className="dwayne-row" style={kobidoMGStyle}>
        <div className="darken">
          <div className="new">
            <p>MASAŻ TWARZY</p>
          </div>
        </div>
        <h1>KOBIDO</h1>
        <p>
          Nieinwazyjny lifting twarzy! <br /> Efekt już po pierwszym zabiegu!
        </p>
      </div>

      <div className="xander-sister-row" style={{ marginTop: '35px' }}>
        <p
          style={{
            display: 'block',
            textAlign: 'left',
            fontSize: '17px',
            width: '80%',
          }}
        >
          <strong>Masaż Kobido</strong> to bardzo intensywny zabieg wywodzący
          się z tradycji medycyny chińskiej. Działa na głębokie warstwy skóry
          powodując:
        </p>
        {/* <p> Kliknij poniżej w przycisk, aby poznać zespół cromed! </p> */}
        <div className="list">
          <span className="">{'>'}</span>
          <p>odmłodzenie skóry,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>zniwelowanie oznak zmęczenia,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>poprawę konturów twarzy,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>aktywizację kolagenu i dotlenienie skóry,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>lepszą absorbcję serum i kremów,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>oczyszczenie z toksyn,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>ujędrnienie i lifting zmarszczek mimicznych,</p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>
            zmniejszenie opuchlizny na twarzy spowodowane zatrzymywaniem wody w
            organizmie,
          </p>
        </div>
        <div className="list">
          <span className="">{'>'}</span>
          <p>pomoc w bólach głowy, bezsenności i bruksizmie.</p>
        </div>
        <div
          style={{ marginTop: '20px' }}
          className="btn-blue"
          onClick={() =>
            // eslint-disable-next-line react/prop-types
            props.redi(
              'https://booksy.com/pl-pl/108831_cromed-centrum-fizjoterapii_fizjoterapia_16974_swarzedz#ba_s=sh_1'
            )
          }
        >
          Rezerwuje!
        </div>
      </div>
    </>
  );
}
function Elon() {
  return (
    <>
      <div className="elon-row" style={contactIMGStyle}>
        <div className="darken">
          <div className="new">
            <p>KONTAKT</p>
          </div>
        </div>
        <h1>SKONTAKTUJ SIĘ Z NAMI!</h1>
        <p>Zapraszamy serdeczenie do kontaktu z nami w celu umówienia wizyty</p>
      </div>
      <div className="elon-sister-row">
        <h2>Nasz media społecznościowe</h2>
        <div className="socials">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 43.75C35.3553 43.75 43.75 35.3553 43.75 25C43.75 14.6447 35.3553 6.25 25 6.25C14.6447 6.25 6.25 14.6447 6.25 25C6.25 35.3553 14.6447 43.75 25 43.75Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.8125 17.1875H29.6875C28.4443 17.1875 27.252 17.6814 26.3729 18.5604C25.4939 19.4395 25 20.6318 25 21.875V43.75"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.75 28.125H31.25"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 32.8125C29.3147 32.8125 32.8125 29.3147 32.8125 25C32.8125 20.6853 29.3147 17.1875 25 17.1875C20.6853 17.1875 17.1875 20.6853 17.1875 25C17.1875 29.3147 20.6853 32.8125 25 32.8125Z"
              stroke="black"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              d="M33.5938 7.03125H16.4062C11.2286 7.03125 7.03125 11.2286 7.03125 16.4064V33.5941C7.03125 38.7719 11.2286 42.9692 16.4062 42.9692H33.5938C38.7714 42.9692 42.9688 38.7719 42.9688 33.5941V16.4064C42.9688 11.2286 38.7714 7.03125 33.5938 7.03125Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.1562 17.1912C36.4507 17.1912 37.5 16.141 37.5 14.8456C37.5 13.5502 36.4507 12.5 35.1562 12.5C33.8618 12.5 32.8125 13.5502 32.8125 14.8456C32.8125 16.141 33.8618 17.1912 35.1562 17.1912Z"
              fill="black"
            />
          </svg>
        </div>
        <Link push to="/contact">
          <div className="btn-blue wide">Kontakt</div>
        </Link>
      </div>
    </>
  );
}

/**
 *
 *
 * To jest tekst. chciałbym, żeby tak niestety nie było bo ponieważ to nie jest na poważnie a tylko na hehe żarty. If u know what i meant. Sprawdzam działanie procentów jak i tego, że to nie działa.
 *
 *
 *
 *
 * to nie jest tekst. nie chciałbym, żeby tak to nareszczie nie było bo ponieważ to jest na serio a tylko na żarty hehe. Sprawdzam działanie procentów jak i tego, że to nie działa
 */
const mainIMGStyle = {
  background: `url(${mainIMG})`,
  backgroundSize: '215%',
  backgroundPosition: '63% 0',
};
const yogaIMGStyle = {
  background: `url(${yogaIMG})`,
  backgroundSize: '150%',
  backgroundPosition: '50% 0',
};
const teamIMGStyle = {
  background: `url(${teamIMG})`,
  backgroundSize: '150%',
  backgroundPosition: '50% 0',
};
const contactIMGStyle = {
  background: `url(${contactIMG})`,
  backgroundSize: '150%',
  backgroundPosition: '50% 0',
};
const kobidoMGStyle = {
  background: `url(${kobidoIMG})`,
  backgroundSize: '150%',
  backgroundPosition: '50% 0',
};

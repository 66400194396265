export const employees = [
    {
        name: 'Karolina Halelujko',
        img: 'karolina.jpg',
        desc: 'Fizjoterapeuta KAROLINA HALELUJKO Licencjat Fizjoterapii, absolwentka Akademii Wychowania Fizycznego w Poznaniu, aktualnie studentka II roku studiów magisterskich uzupełniających w Wyższej Szkole Edukacji i Terapii w Poznaniu. Jestem osobą energiczną, która musi być w ciągłym ruchu. Uwielbiam kontakt z ludźmi, a jedyne czego nie lubię, to rutyna. Dlatego właśnie zawód, który wybrałam powoduje, że do pracy idę z uśmiechem na twarzy. Każdy Pacjent jest inny, niepowtarzalny, daje nowe wyzwania i skłania do szukania wcześniej nieodkrytych rozwiązań. Największą satysfakcją jest dla mnie radość Pacjenta, gdy jego ból lub uraz odchodzą w niepamięć. Pracuję głównie z Pacjentami ortopedycznymi, neurologicznymi, reumatologicznymi oraz z dolegliwościami bólowymi. Jednakże masaż klasyczny, leczniczy czy relaksacyjny nie jest mi obcy. Staram się też nieustannie zgłębiać swoją wiedzę, dlatego często odbywam różnego rodzaju kursy i szkolenia. Ponadto interesuję się treningiem medycznym, kształtowaniem sylwetki oraz dietetyką. KURSY I SZKOLENIA: - „Masaż tkanek głębokich i rozluźnianie mięśniowo – powięziowe z anatomią palpacyjną” - „Ocena Funkcjonalna kończyny górnej u pacjenta po udarze mózgu” - „ Nowoczesne postępowanie fizjoterapeutyczne w problemie nietrzymania moczu u kobiet” - „Praca z tkanką łączną w terapii blizn” - „ Wczesna diagnostyka i terapia chorób zwyrodnieniowych u młodych osób'
    },
    {
        name: 'Marlena Doniec',
        img: 'marlena.jpg',
        desc: 'Jestem magistrem fizjoterapii, absolwentką Uniwersytetu Medycznego oraz studiów podyplomowych w Wyższej Szkole Edukacji i Terapii na kierunku Kompleksowa Terapia Manualna oraz licznych szkoleń i certyfikowanych kursów. Praca z ciałem pacjenta i pomoc w odzyskaniu pełnej funkcjonalności w życiu prywatnym i zawodowym jest dla mnie celem nadrzędnym. Problemy, z którymi zgłaszają się do mnie pacjenci, rozpatruję w duchu medycyny holistycznej. Na co dzień w swojej pracy wykorzystuję techniki masażu tkanek głębokich, rozluźniania mięśniowo-powięziowego, terapię punktów spustowych, pinoterapię oraz terapię wisceralną. Powrót do zdrowia jest procesem, przez który przechodzę wspólnie z pacjentem.'

    },
    // {
    //     name: 'Ada Szydłowska',
    //     img: <svg width="155" height="146" viewBox="0 0 155 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M77.2052 127.641C109.185 127.641 135.11 103.149 135.11 72.9375C135.11 42.7258 109.185 18.2344 77.2052 18.2344C45.2255 18.2344 19.3008 42.7258 19.3008 72.9375C19.3008 103.149 45.2255 127.641 77.2052 127.641Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    //     <path d="M77.205 91.1719C90.5299 91.1719 101.332 80.9671 101.332 68.3789C101.332 55.7907 90.5299 45.5859 77.205 45.5859C63.8801 45.5859 53.0781 55.7907 53.0781 68.3789C53.0781 80.9671 63.8801 91.1719 77.205 91.1719Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    //     <path d="M38.4805 113.608C42.1152 106.857 47.6566 101.188 54.4912 97.2277C61.3258 93.2678 69.187 91.1719 77.2045 91.1719C85.2221 91.1719 93.0833 93.2678 99.9179 97.2276C106.752 101.187 112.294 106.857 115.929 113.608" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    //     </svg>,
    //     desc: 'Jestem Ada, praktykuję yogę od 5 lat i od 4 lat również uczę. W 2018 ukończyłam nauczycielski kurs yogi w Indiach, gdzie zrozumiałam i poczułam, że yoga to nie tylko fizyczne ćwiczenia, ale także styl życia. W 2021 ukończyłam nauczycielski kurs yogi organizowany przez Uniwersytet Adama Mickiewicza w Poznaniu, który ugruntował moją praktyczną wiedzę na temat yogi. Yoga niesie ze sobą niezwykle mądre i dojrzałe spojrzenie na otaczający nas świat i ludzi, a przede wszystkim przynosi ukojenie dla naszego rozbieganego i nie współpracującego z nami umysłu. Istnieje powiedzenie, które usłyszałam ucząc się w Indiach “Umysł może być Twoim najlepszym przyjacielem, lub najgroźniejszym wrogiem.” Yoga pozwoliła mi zaprzyjaźnić się z moim umysłem i zakorzenić w nim przekonania, które procentują każdego dnia w piękne doświadczenia. Życzę każdej istocie, życia w takim dobrostanie i dlatego moją pasją jest dzielenie się wiedzą i praktyką, która umożliwiła mi takie życie. Namaste.'
    // },
    // {
    //     name: 'Marcin Gach',
    //     img: 'marcin.jpg',
    //     desc: 'Magister Fizjoterapii i dyplomowany masażysta, zafascynowany terapią manualną, medycyną naturalną oraz zdrowym podejściem do życia. Kieruję szczególną uwagę na metody stawiające w centrum pacjenta, zamiast skupiać się jedynie na konkretnych jednostkach chorobowych. Edukuję się od wielu lat, na wielu płaszczyznach, uczestnicząc w szkoleniach oraz każdą wolną chwilę spędzam czytając. Wykorzystuje w swojej pracy głównie techniki pinoterapii, masaż tkanek głębokich, terapii punktów spustowych oraz manipulacji kręgosłupa. Na pierwszym miejscu zawsze stawiam dobro i bezpieczeństwo pacjenta. Z mojej perspektywy widzę wyraźnie jak wiele można zdziałać tak, by droga do przerwania koła bólowego i postawienia pacjenta na nogi była krótka, a terapia skuteczna.'
    // },
]